@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-Regular.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-Regular.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-RegularItalic.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-RegularItalic.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-RegularItalic.otf') format('otf');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-Medium.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-Medium.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-Medium.otf') format('otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-MediumItalic.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-MediumItalic.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-MediumItalic.otf') format('otf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-Bold.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-Bold.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-Bold.otf') format('otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src:
    url('/public/fonts/ABCMonumentGrotesk-BoldItalic.woff2') format('woff2'),
    url('/public/fonts/ABCMonumentGrotesk-BoldItalic.woff') format('woff'),
    url('/public/fonts/ABCMonumentGrotesk-BoldItalic.otf') format('otf');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
