@import './fonts.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    @apply box-border antialiased;
    
    outline: none;
    text-rendering: geometricprecision !important;
  }

  body {
    @apply bg-background text-foreground min-h-screen font-sans text-body antialiased;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :root {
    --border: 210 10% 75%;
    --input: 210 10% 85%;
    --ring: 210 8% 5%;

    --background: 216 10% 90%;

    --foreground: 210 8% 5%;
    --foreground-secondary: 209 10% 50%;
    --foreground-inverse: 210 8% 95%;

    --surface: 0 0% 100%;
    --surface-muted: 220 12% 95%;
    --surface-inverse: 210 8% 5%;

    --intent-primary: 210 8% 5%;
    --intent-primary-foreground: 0 0% 100%;
    --intent-primary-hover: 210 8% 15%;
    
    --intent-secondary: 220 12% 89%;
    --intent-secondary-foreground: 210 8% 5%;
    --intent-secondary-hover: 220 11% 80%;
    
    --intent-warning: 38 100% 93%;
    --intent-warning-foreground: 38 88% 36%;
    --intent-warning-hover: 38 98% 85%;

    --intent-danger: 8 99% 65%;
    --intent-danger-foreground: 210 8% 95%;
    --intent-danger-hover: 8 94% 55%;

    --accent-purple: 249 95% 85%;
    --accent-purple-secondary: 249 96% 65%;
    --accent-orange: 23 99% 65%;
    --accent-orange-secondary: 23 92% 42%;
    --accent-yellow: 38 99% 65%;
    --accent-yellow-secondary: 38 88% 36%;
    --accent-olive: 55 32% 60%;
    --accent-olive-secondary: 55 56% 30%;
    --accent-teal: 158 44% 95%;
    --accent-teal-secondary: 167 90% 30%;
    --accent-blue: 219 96% 70%;
    --accent-blue-secondary: 219 95% 50%;
    --accent-brown: 36 43% 65%;
    --accent-brown-secondary: 36 50% 35%;
  }
}

@layer utilities {
  .h-fill {
    /* fallback */
    height: 100vh;
    height: stretch;
    /* https://caniuse.com/?search=dvh */
    height: 100dvh;
  }

  .min-h-fill {
    /* fallback */
    min-height: 100vh;
    min-height: stretch;
    /* https://caniuse.com/?search=dvh */
    min-height: 100dvh;
  }

  .max-h-fill {
    /* fallback */
    max-height: 100vh;
    max-height: stretch;
    /* https://caniuse.com/?search=dvh */
    max-height: 100dvh;
  }

  /* https://stackoverflow.com/questions/262158/disabled-input-text-color-on-ios */
  .safari-disabled-input-color:disabled {
    -webkit-text-fill-color: white;
    -webkit-opacity: 1;
    opacity: 1;
  }
}

@media (prefers-reduced-motion) {
  * {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

body > iframe {	
  /**	
  This makes it possible to embed e.g. Plaid/Fuse modals on top of Radix dialogs 	
  When a Radix dialog is open, Radix adds `pointer-events: none` to the body, which makes it impossible to interact with the iframe otherwise	
  */	
  pointer-events: auto;	
}	
